import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from 'src/modules/authentication/services/auth/auth.service';
import {Observable, Subject} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {CustomerState} from 'src/modules/customer/state/customer.state';
import {ICustomer, PermissionNode} from 'src/modules/customer/model/customer.model';
import {takeUntil} from 'rxjs/operators';
import {GetAuthCustomer, GetCustomer} from 'src/modules/customer/state/customer.actions';
import {environment} from 'src/environments/environment';
import {GetPartner} from 'src/modules/partner/state/partner.actions';
import {PartnerState} from 'src/modules/partner/state/partner.state';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {
    faArrowRotateRight,
    faArrowUpWideShort,
    faBolt,
    faCalendar,
    faCalendarCheck,
    faCircleCheck,
    faCircleInfo,
    faCircleUser,
    faCog,
    faExclamationCircle,
    faMessageCheck,
    faPartyHorn,
    faPenToSquare,
    faRobot,
    fas,
} from '@fortawesome/pro-solid-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';
import {faDropbox, faMicrosoft, faWindows} from '@fortawesome/free-brands-svg-icons';
import {
    faAcronis,
    faAzure,
    faDropboxSign,
    faEset,
    faExclaimer,
    faKeepit
} from 'src/assets/fa-custom-icons/fa-custom-icons';
import {NavController} from '@ionic/angular/standalone';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {addIcons} from 'ionicons';
import {document, layers, logoDropbox, logoMicrosoft, people} from 'ionicons/icons';
import {
    faArrowRotateRight as faArrowRotateRightLight,
    faInfoCircle as faInfoCircleLight,
    faSidebarFlip
} from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    @Select(CustomerState.getCustomerObject) customer$: Observable<ICustomer>;
    @Select(PartnerState.getPartnerLogo) partnerLogo$: Observable<string>;
    @Select(CustomerState.isCustomerLoading) isCustomerLoading$: Observable<boolean>;

    public appPages = [
        {
            title: 'Overview',
            url: '/overview',
            icon: 'file',
            type: 'fas',
            customIcon: '',
            permission: PermissionNode.manageMicrosoftSeats,
        },
        {
            title: 'Microsoft Subscriptions',
            url: '/microsoft/microsoft-subscriptions',
            icon: 'microsoft',
            type: 'fab',
            customIcon: '',
            permission: PermissionNode.MicrosoftSubscriptionsRead,
        },
        {
            title: 'Microsoft Users',
            url: '/microsoft/microsoft-users',
            icon: 'users',
            type: 'fas',
            customIcon: '',
            permission: PermissionNode.manageMicrosoftSeats,
        },
        {
            title: 'Azure Plan',
            url: '/microsoft/azure',
            icon: '',
            type: 'fas',
            customIcon: '../assets/images/azure-icon.svg',
            permission: PermissionNode.AzurePlanRead,
        },
        {
            title: 'IaaS',
            url: '/iaas/projects',
            icon: 'layer-group',
            type: 'fas',
            customIcon: '',
            permission: PermissionNode.infrastructureManagement,
        },
        {
          title: 'Dropbox',
          url: '/dropbox',
          icon: 'dropbox',
          type: 'fab',
          customIcon: '',
          permission: PermissionNode.dropboxManagement,
        },
    ];

    public selectedIndex: number;

    public environment = environment;
    protected _onDestroy = new Subject<void>();
    protected readonly faAzure = faAzure;

    constructor(private router: Router, private route: ActivatedRoute, private library: FaIconLibrary, public authService: AuthService, private store: Store, private navController: NavController) {
        library.addIconPacks(fas, far, fad);
        library.addIcons(
            faCog,
            faCircleInfo,
            faPartyHorn,
            faCircleUser,
            faBolt,
            faCircleCheck,
            faRobot,
            faExclamationCircle,
            faCalendar,
            faArrowRotateRight,
            faArrowUpWideShort,
            faSidebarFlip,
            faArrowRotateRightLight,
            faInfoCircleLight,
            faCalendarCheck,
            faMessageCheck,
            faWindows,
            faMicrosoft,
            faDropbox,
            faPenToSquare,
            faDropboxSign as any,
            faAcronis as any,
            faAzure as any,
            faEset as any,
            faExclaimer as any,
            faKeepit as any,
        );
        addIcons({
            people,
            document,
            logoMicrosoft,
            logoDropbox,
            layers,
        });
        this.initializeApp();
    }

    initializeApp() {
        this.isCustomerLoading$.pipe(takeUntil(this._onDestroy)).subscribe((load) => {
            if (load) {
                this.store.dispatch(new GetAuthCustomer());
                this.store.dispatch(new GetCustomer());
                this.store.dispatch(new GetPartner());
            }
        });
    }

    ngOnInit() {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const currentRoute = val.url;
                this.setSelectedIndex(currentRoute);
            }
        });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public partnerLogoError($event) {
        // Set the logo to 1x1 transparent pixel in case of error
        $event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/1wY038AAAAASUVORK5CYII=';
    }

    public navigateTo(url: string, index: number) {
        this.selectedIndex = index;
        this.navController.navigateRoot([url]);
    }

    private setSelectedIndex(currentRoute) {
        const currentIndex = this.appPages.findIndex((x) => x.url === currentRoute);
        if (currentIndex !== -1) this.selectedIndex = currentIndex;
    }
}
