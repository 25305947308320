// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: window['env']['production'] || false,
  apiUrl: window['env']['apiUrl'] || 'https://portal.api.alpha.cloudfactory.dk/',
  logoUrl: window['env']['logoUrl'] || 'https://portal.api.alpha.cloudfactory.dk/v2/partners/Partners/{id}/Logos',
  vmConsoleUrl: window['env']['vmConsoleUrl'] ||'https://terminal.alpha.cloudfactory.dk',
  dropboxApiUrl: window["env"]["dropboxApiUrl"] || "http://10.245.110.243:8002",
  dropboxApiUrlV2: window["env"]['dropboxApiUrlV2'] || "https://portal.api.alpha.cloudfactory.dk/dropbox/v2",
  catalogueApiUrl: window["env"]["catalogueApiUrl"] || "https://portal.api.alpha.cloudfactory.dk/v2/catalogue/",
  microsoftV2API: window["env"]["microsoftV2API"] || "https://portal.api.alpha.cloudfactory.dk/v2/microsoft",

  auth: {
    authorizationParams: {
      audience:
        window['env']['auth_audience'] || 'http://api.minelicenser.dk/',
      redirect_uri: `${window.location.origin}`,
    },
    domain: window['env']['auth_domain'] || 'alpha.customerportalauth.com',
    clientId: window['env']['auth_clientid'] || 'bFcEjGy98SN0Bvg7NDD5t6GaaMLQC6jO',
    useRefreshTokens: true,
    cacheLocation: 'localstorage' as const
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
